import React from "react"
import PropTypes from "prop-types"

const Cloud = ({ color }) => {
  return (
    <svg
      version="1.1"
      role="img"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="72" height="49" 
      viewBox="0 0 72 49"
    >
      <path fillRule="evenodd" clipRule="evenodd" d="M15.8538 14.9036C18.4181 6.28866 26.465 0 36 0C45.535 0 53.5819 6.28866 56.1463 14.9036C65.0203 15.6072 72 22.959 72 31.9242C72 41.3549 64.2769 49 54.7499 49H17.25C7.72308 49 0 41.3549 0 31.9242C0 22.959 6.97979 15.6072 15.8538 14.9036ZM27.4991 23.5775C26.6204 24.4472 26.6204 25.8575 27.4991 26.7273C28.3779 27.597 29.8024 27.597 30.681 26.7273L33.9995 23.4423V35.3894C33.9995 36.6195 35.0069 37.6166 36.2495 37.6166C37.4922 37.6166 38.4995 36.6195 38.4995 35.3894V23.4423L41.8181 26.7273C42.6967 27.597 44.1214 27.597 45 26.7273C45.8786 25.8575 45.8786 24.4472 45 23.5775L37.8405 16.4903C36.9619 15.6205 35.5372 15.6205 34.6586 16.4903L27.4991 23.5775Z" fill={color}/>
    </svg>
  )
}

Cloud.propTypes = {
  color: PropTypes.string,
}

Cloud.defaultProps = {
  color: "#000000",
}

export default Cloud