import React, {useEffect} from "react"
import styled from "styled-components"
import spot from "content/images/spot.svg"
import { language } from "config"

const StyledAnimation = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  padding: 0.5rem 2rem;
  justify-content: center;
  .animation {
    width: 10rem;
    height: 8rem;
    background-image: url(${spot});
    background-size: 10rem 8rem;
    display: flex;
    background-repeat: no-repeat;
    margin-bottom: 1.5rem;
    #lottie21 {
      width: 10rem;
    }
  }
  .text {
    font-size: 0.932rem;
    line-height: 1rem;
    color: #9DB9D6!important;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    margin-bottom: 0;
    .text {
      font-size: 0.832rem;
      line-height: 1rem;
      color: #3E4655;
    }
  }
`

const Animation = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://img.riminder.net/animations/parsing-animation.js"
    script.async = true
    document.body.appendChild(script)
  }, [])

  return (
    <StyledAnimation>
      <div className="animation">
        <div id="lottie21"></div>
      </div>
      <div className="text text-center">
        {language.animation_message}
      </div>
    </StyledAnimation>
  )
}

export default Animation
