import React from "react"
import PropTypes from "prop-types"

const Attach = ({ color }) => {
  return (
    <svg
      version="1.1"
      role="img"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="19"
      height="24"
      viewBox="0 0 19 24"
    >
      <path d="M11.4389 0.0214909C9.30189 0.192871 7.26876 1.38319 6.09921 3.37583L0.109221 13.5805C0.0564389 13.6704 0.0218901 13.7698 0.00754678 13.8731C-0.00679651 13.9764 -0.000653289 14.0814 0.0256256 14.1823C0.0519045 14.2832 0.0978045 14.378 0.160705 14.4611C0.223605 14.5443 0.302274 14.6142 0.392219 14.667C0.482164 14.7198 0.581624 14.7543 0.68492 14.7686C0.788217 14.783 0.893326 14.7768 0.994247 14.7506C1.09517 14.7243 1.18992 14.6784 1.27311 14.6155C1.35629 14.5526 1.42626 14.474 1.47904 14.384L7.46849 4.1789C8.91543 1.71368 12.0207 0.861465 14.4489 2.24048C16.877 3.6195 17.6713 6.6612 16.2245 9.12644L10.235 19.3311L9.78579 20.0974C8.88206 21.6372 6.90843 22.1599 5.33416 21.2658C3.75986 20.3718 3.24156 18.459 4.14526 16.9193L9.5418 7.72547L10.6661 5.80982C11.0716 5.119 11.9491 4.88496 12.6603 5.28892C13.3716 5.69286 13.5961 6.52774 13.1907 7.21854L7.12007 17.5617C7.06485 17.6516 7.02813 17.7517 7.01205 17.856C6.99598 17.9604 7.00088 18.0668 7.02647 18.1693C7.05206 18.2717 7.09782 18.3679 7.16108 18.4525C7.22434 18.537 7.30382 18.608 7.39488 18.6614C7.48593 18.7149 7.58674 18.7496 7.69139 18.7636C7.79604 18.7777 7.90243 18.7707 8.00435 18.7431C8.10627 18.7155 8.20166 18.6679 8.28494 18.603C8.36823 18.5381 8.43773 18.4573 8.48939 18.3652L14.5605 8.02158C15.4007 6.59005 14.8913 4.72999 13.445 3.90864C11.9988 3.08725 10.1366 3.57474 9.29628 5.00623C9.29628 5.00641 9.29628 5.00661 9.29628 5.00678L8.17198 6.92191L2.77597 16.1163C1.43749 18.3967 2.24073 21.3347 4.55 22.6462C6.85925 23.9576 9.8171 23.181 11.1556 20.9005L11.6048 20.1341L17.5942 9.92951C19.4654 6.74128 18.4147 2.66691 15.2335 0.860205C14.0405 0.182685 12.7211 -0.0813391 11.4388 0.0214909H11.4389Z" fill={color} fillOpacity="0.25"/>
    </svg>
  )
}

Attach.propTypes = {
  color: PropTypes.string,
}

Attach.defaultProps = {
  color: "#000000",
}

export default Attach